import { useState } from "react";
// import { CurrencyAmount } from "@uniswap/sdk-core";
import { NFTMinterAdminWidget } from "easydefi_package";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { GraphQLClient } from "graphql-request";
import { fetchUsersNFTData, ParsedUserNFTData } from "./data";
// import { useUserMiningNFTData } from "./subgraph";
import { ChainId, Token } from "@uniswap/sdk-core";
// import { parseUnits } from "ethers/lib/utils";
import tryParseCurrencyAmount from "./tryParseCurrencyAmount";
import { useUserMiningNFTData } from "./subgraph";

function MinterContent() {
  const csvConfig = mkConfig({
    showColumnHeaders: false,
    columnHeaders: [
      { key: "id", displayLabel: "" },
      { key: "balance", displayLabel: "" }
    ],
    filename: "nft_holder",
    useTextFile: false
    // quoteCharacter: ""
  });

  // const contract = useMinterContract(minter_address[137]);
  const { data: fetchedData } = useUserMiningNFTData();
  const [data, setData] = useState<ParsedUserNFTData[]>([]);
  const [distribution, setDistribution] = useState("0");

  const sharedToken = new Token(
    ChainId.MAINNET,
    "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    18,
    "BTCB",
    "BTCB Token"
  );

  return (
    <NFTMinterAdminWidget
      onCSVDownload={async () => {
        const loyaltyGraph = new GraphQLClient(
          "https://gateway-arbitrum.network.thegraph.com/api/185a48a20ab1fb58bfca48f743bdbfba/subgraphs/id/CEJqUdzUEUSnGgSqmVWofnGnFXJe3eNm2uYTdG6h9PCZ"
        );

        const currentNFTData = await fetchUsersNFTData(loyaltyGraph);

        if (currentNFTData) {
          setData(currentNFTData);
          const exportData: any[] = [];

          let total = 0;

          currentNFTData.map((item) => {
            total = total + item.balance;
          });

          try {
            const amount = tryParseCurrencyAmount(distribution, sharedToken);
            const portionAmount = amount?.divide(total);

            const otherPortion = parseFloat(distribution) / total;

            if (parseFloat(portionAmount?.toExact() || "0") > otherPortion) {
              currentNFTData.map((item) => {
                // const balance = portionAmount?.multiply(item.balance);
                exportData.push({ id: item.id, balance: otherPortion * item.balance || 0 });
              });
            } else {
              currentNFTData.map((item) => {
                const balance = portionAmount?.multiply(item.balance);
                exportData.push({ id: item.id, balance: parseFloat(balance?.toExact() || "0") || 0 });
              });
            }

            const csv = generateCsv(csvConfig)(exportData);
            download(csvConfig)(csv);
          } catch (error) {
            console.log(error);
          }
        }
      }}
      onDistributionUpdate={setDistribution}
      holders={data?.length || fetchedData?.length}
    />
  );
}

export default MinterContent;
